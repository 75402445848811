<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogTestVisible"
    title="新增数据"
    width="835px"
  >
    <el-form :model="form" size="small" label-width="95px">
      <el-form-item label="开票项目:">
        <div>
          <el-button @click="addShotNameArr" type="primary" plain>
            新增
          </el-button>
          <p
            v-for="(item, index) in form.shotNameArr"
            :key="index"
            style="margin-top: 10px;"
          >
            <el-tag
              v-for="tag in item"
              :key="tag"
              :type="tag.match('!') ? 'danger' : 'primary'"
              style="margin-right: 5px;margin-bottom: 5px;"
              closable
              @close="handleCloseShotName(tag, item)"
            >
              <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
              <span v-else>{{ tag }}</span>
            </el-tag>
            <el-input
              v-if="item.inputVisible"
              ref="InputRef"
              v-model="item.inputValue"
              @keyup.enter="handleInputShotName(item, item.inputValue)"
              @blur="handleInputShotName(item, item.inputValue)"
              style="width:120px"
            />
            <el-button v-else @click="showInputShotName(item)">
              +添加
            </el-button>
          </p>
        </div>
      </el-form-item>
      <el-form-item label="开票明细:">
        <div>
          <el-button @click="addItemNameArr" type="primary" plain>
            新增
          </el-button>
          <p
            v-for="(item, index) in form.itemNameArr"
            :key="index"
            style="margin-top: 10px;"
          >
            <el-tag
              v-for="tag in item"
              :key="tag"
              :type="tag.match('!') ? 'danger' : 'primary'"
              style="margin-right: 5px;margin-bottom: 5px;"
              closable
              @close="handleCloseItemName(tag, item)"
            >
              <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
              <span v-else>{{ tag }}</span>
            </el-tag>
            <el-input
              v-if="item.inputVisible"
              ref="InputRef"
              v-model="item.inputValue"
              @keyup.enter="handleInputItemName(item, item.inputValue)"
              @blur="handleInputItemName(item, item.inputValue)"
              style="width:120px"
            />
            <el-button v-else @click="showInputItemName(item)">
              +添加
            </el-button>
          </p>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogTestVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveData"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogTestVisible: false,
      form: {
        itemNameArr: [],
        itemName: "",
        shotNameArr: [],
        shotName: "",
        id: 0,
        parentId: 0,
        children: null,
      },
    };
  },

  methods: {
    //测试数据
    init(id,newId) {
      this.resetForm();
      this.form.id = newId;
      if (id) {
        this.form.parentId = id;
        this.form.parent_id = id;
      }
      this.dialogTestVisible = true;
    },
    resetForm() {
      this.form = {
        itemNameArr: [],
        itemName: "",
        shotNameArr: [],
        shotName: "",
        id: 0,
        parentId: 0,
        children: null,
      };
    },
    //新增对方户名
    // 开票明细
    addItemNameArr() {
      this.form.itemNameArr.push([]);
    },
    handleCloseItemName(tag, row) {
      row.splice(row.indexOf(tag), 1);
    },
    showInputItemName(row) {
      row.inputVisible = true;
    },
    handleInputItemName(row, value) {
      if (value) {
        this.$confirm("是否包含" + value + "？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            row.push(value);
          })
          .catch(() => {
            row.push("!" + value);
          });
      }
      row.inputVisible = false;
      row.inputValue = "";
    },

    //开票项目
    addShotNameArr() {
      this.form.shotNameArr.push([]);
    },
    handleCloseShotName(tag, row) {
      row.splice(row.indexOf(tag), 1);
    },
    showInputShotName(row) {
      row.inputVisible = true;
    },
    handleInputShotName(row, value) {
      if (value) {
        this.$confirm("是否包含" + value + "？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            row.push(value);
          })
          .catch(() => {
            row.push("!" + value);
          });
      }
      row.inputVisible = false;
      row.inputValue = "";
    },
    saveData() {
      //保存时处理开票明细
      let orName = "";
      this.form.itemNameArr.map((v) => {
        orName += v.join("||") + "&&";
      });
      this.form.itemName = orName.substring(0, orName.length - 2);

      //保存时处理开票项目
      let shotName = "";
      this.form.shotNameArr.map((v) => {
        shotName += v.join("||") + "&&";
      });
      this.form.shotName = shotName.substring(0, shotName.length - 2);
      this.$emit("submit", this.form);
      this.dialogTestVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.cancel {
  color: #17a2b8;
  cursor: pointer;
  font-size: 12px;
  margin-left: 20px;
}
.el-radio {
  margin-right: 20px;
}
</style>
